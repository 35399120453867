<template>
    <div class="pay-box">
        <el-dialog width="80%" :visible.sync="payVisible">
            <div class="payType">
                <div>选择支付方式</div>
                <div>
                    <el-radio v-model="pay_type" label="0" :disabled="payDis==1">线上支付</el-radio>
                    <div class="onLine">
                        <div>温馨提示：选择线上或线下支付的方式后，后续增加或修改保单内的
                            人员信息时，将只能使用首次支付选择的支付方式。
                        </div>
                        <div>
                            <img src="../../image/pay/1.png" alt="">
                        </div>
                        <div>
                            <el-button style="width: 45%" type="primary" :disabled="pay_type=='1'"
                                       @click="onpayMoney()">去支付
                            </el-button>
                        </div>
                    </div>
                </div>
                <div>
                    <el-radio v-model="pay_type" label="1" :disabled="payDis==0">线下支付</el-radio>
                    <div class="offline">
                        <h2>保费支付账户</h2>
                        <div>
                            <div>账&nbsp;&emsp;户：<span>{{company_bank_no }}</span></div>
                            <div>开户行：<span>{{ company_bank_name }}</span></div>
                            <div>账户名：<span>{{ company_name }}</span></div>
                        </div>
                        <h2>缴费凭证上传</h2>
                        <div>
                            <div>
                                <div>合计保费</div>
                                <div>{{ totalMoney }}</div>
                            </div>
                            <div>
                                <div><span style="color:red">*</span> 实际付款人</div>
                                <div>
                                    <el-input :disabled="pay_type=='0'" v-model="real_name"
                                              placeholder="请输入付款人"></el-input>
                                </div>
                            </div>
                            <div>
                                <div><span style="color:red">*</span> 缴费凭证扫描件</div>
                                <div>
                                    <el-upload
                                            :disabled="pay_type=='0'"
                                            class="upload-demo"
                                            :action="baseUrl+'/api/order/orders/uploadCard'"
                                            :data="pay_voucherup"
                                            :on-success="pay_vou">
                                        <el-button :disabled="pay_type=='0'" size="small" type="primary">点击上传
                                        </el-button>
                                    </el-upload>
                                </div>
                            </div>
                        </div>
                    </div>
                    <el-button type="primary" style="width: 200px;margin-top: 50px;margin-left: calc( 50% - 100px)"
                               :disabled="pay_type=='0'" @click="offpayMoney()">提交审核
                    </el-button>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "pay",
        props: {
            num_id: {//线上支付使用
                default: ""
            },
            company_bank_name: {//线下支付使用
                default: ""
            },
            company_bank_no: {//线下支付使用
                default: ""
            },
            company_name: {//线下支付使用
                default: ""
            },
            totalMoney: {//总保费
                default: '0',
            },
            paytype: {
                default: '0',//线上支付0   线下1  线下线上都可以4
            },
            payDis: {
                default: 2  // 0 禁用线下   1禁用线上  不是0 1线下线上都可以
            },
            change_id: {
                default: ''
            }
        },
        data() {
            return {
                baseUrl: '',
                pay_type: "",
                real_name: '',//线下支付付款人
                pay_voucher: '',//线下支付付款凭证
                pay_voucherup: {},//上传支付凭证
                payVisible: false
            }
        },
        created() {
            this.baseUrl = this.$store.state.baseUrl
            this.pay_type = this.paytype
        },
        mounted() {
            this.pay_voucherup = {
                authCheck: 0,
                login_type: 1,
                source: 4,
                type: 5,
                token: this.$store.state.logininformation.token
            }
        },
        methods: {
            //线下支付上传凭证
            pay_vou(e) {
                console.log(e)
                this.pay_voucher = e.data.url
                console.log(this.change_id)
            },
            onpayMoney() {//线上支付
                if (this.change_id != "") {//批增支付
                    let that = this;
                    let loading = that.$loading({
                        lock: true,
                        text: 'Loading',
                        background: 'rgba(0, 0, 0, 0.5)'
                    });
                    that.axios({
                        url: that.$store.state.baseUrl + '/api/order/orders/getOrderPayUrl',
                        method: 'POST',
                        params: {
                            authCheck: 0,
                            source: 4,
                            login_type: that.$store.state.login_type,
                            token: that.$store.state.logininformation.token,
                            change_id: that.change_id,
                        }
                    }).then(function (res) {
                        console.log(res)
                        loading.close();
                        if (res.data.code == 200) {
                            window.open(res.data.data.pay_url, '_blank');
                             if(that.$route.name=='correctOrder'){
                                // that.$router.go(0)
                            }else{
                                that.$router.push({name: "correctOrder"})
                            }
                        } else {
                            that.$message.error(res.data.msg);
                        }
                        // that.$router.go(0)                
                    }).catch(function (error) {
                        console.log(error);
                    });
                } else {//投保支付
                    let that = this
                    let _data = {
                        authCheck: 0,
                        source: 4,
                        login_type: 1,
                        token: that.$store.state.logininformation.token,
                        num_id: that.num_id,
                        pay_type: 0
                    }
                    let loading = that.$loading({
                        lock: true,
                        text: 'Loading',
                        background: 'rgba(0, 0, 0, 0.5)'
                    });
                    that.axios({
                        url: this.$store.state.baseUrl + '/api/order/orders/toPayOrder',
                        method: 'POST',
                        params: _data
                    }).then(function (res) {
                        console.log(res)
                        loading.close();
                        if (res.data.code == 200) {
                            window.open(res.data.data, '_blank');
                            console.log(that.$route.name)
                            if(that.$route.name=='guaranteeSlip'){
                                that.$router.go(0)
                            }else{
                                that.$router.push({name: 'guaranteeSlip'})
                            }
                            
                        } else {
                            that.$message.error(res.data.msg);
                        }
                    }).catch(function (error) {
                        console.log(error);
                    });
                }


            },
            offpayMoney() {//线下支付
                let that = this
                let _data = {
                    authCheck: 0,
                    source: 4,
                    login_type: 1,
                    token: that.$store.state.logininformation.token,
                    num_id: that.num_id,
                    pay_type: 1,
                    real_name: that.real_name,
                    pay_voucher: that.pay_voucher
                }
                if (this.change_id!= "") {//批曾支付
                    _data.change_id = that.change_id
                }
                if (that.real_name) {
                    if (that.pay_voucher) {
                        let loading = that.$loading({
                            lock: true,
                            text: 'Loading',
                            background: 'rgba(0, 0, 0, 0.5)'
                        });
                        that.axios({
                            url: this.$store.state.baseUrl + '/api/order/orders/toPayOrder',
                            method: 'POST',
                            params: _data
                        }).then(function (res) {
                            console.log(res)
                            loading.close();
                            if (res.data.code == 200) {
                                that.$confirm('提交审核成功', '提示', {
                                    confirmButtonText: '确定',
                                    type: 'success',
                                    center: true,
                                    showCancelButton: false
                                }).then(() => {
                                    if (that.change_id!= "") {
                                        if(that.$route.name=='correctOrder'){
                                            that.$router.go(0)
                                        }else{
                                            that.$router.push({name: 'correctOrder'})
                                        }
                                    } else {
                                        if(that.$route.name=='guaranteeSlip'){
                                            that.$router.go(0)
                                        }else{
                                            that.$router.push({name: 'guaranteeSlip'})
                                        }
                                    }
                                })
                            } else {
                                that.$message.error(res.data.msg);
                            }
                        }).catch(function (error) {
                            console.log(error);
                        });
                    } else {
                        that.$message.error('请上传缴费凭证');
                    }
                } else {
                    that.$message.error('请填写实际付款人');
                }
            },
            open() {
                this.payVisible = true
            }
        },
        watch: {
            paytype: {
                handler(e) {
                    console.log(e)
                    this.pay_type = e
                },
                deep: true
            }
        }
    }
</script>

<style scoped lang="scss">
    .payType {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 0 8%;
        justify-content: flex-start;
        flex-wrap: wrap;

        & > div {
            width: 44%;
            text-align: left;
        }

        & > div:nth-child(1) {
            margin-bottom: 30px;
            width: 100%;
            height: 54px;
            line-height: 54px;
            background: rgba(249, 249, 249, 1);
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 400;
            color: rgba(52, 52, 52, 1);
        }

        & > div:nth-child(2) {
            height: 100%;
            position: relative;
            padding-right: 5%;
            border-right: 1px solid rgba(230, 230, 230, 1);

            .onLine {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                & > div {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    margin-top: 60px;
                }

                & > div:nth-child(1) {
                    box-sizing: border-box;
                    margin-top: 40px;

                }

                img {
                    width: 35%;
                }
            }
        }

        & > div:nth-child(3) {
            margin-left: 5%;

            .offline {
                & > h2 {
                    width: 100%;
                    height: 43px;
                    background: rgba(241, 244, 246, 1);
                    line-height: 43px;
                    box-sizing: border-box;
                    padding-left: 15px;
                }

                & > div:nth-child(2) {
                    line-height: 30px;
                }

                & > div:nth-child(4) {
                    line-height: 45px;

                    & > div {
                        display: flex;

                        & > div:nth-child(1) {
                            width: 30%;
                        }
                    }
                }
            }
        }
    }
</style>
