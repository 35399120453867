<template>
    <div class="claim">
        <el-dialog :visible.sync="dialogVisible" width="50%" :modal="true" :modal-append-to-body="false" :lock-scroll="true"><div class="dialog"><div>快速理赔，请关注入锐鹿小程序申请赔付</div>
        <div>
            <img src="../../../image/wx.jpg" alt="">
        </div>
            <div>如果您的员工发生意外，请按以下步骤申请理赔</div>
            <div>第一步：在 "我的保险" 页面 点击订单列表"保障中", 选择要理赔订单，在订单详情页点击"我要理赔"填写报案信息</div>
            <div>第二步：点击理赔进度查询，提交理赔材料，等待审核
                </div>
                <div>根据损失金额大小，按照如下要求提交材料:</div>
                <div>A：10000元以下小额损失，在线提交理赔材料
                </div>
                <div>
                    B：10000元以上大额损失，客服一对一服务，线下提交材料
                </div>
                <div>
                    材料提交完成，等待审核
                </div>
                <div>
                    第三步：审核理赔材料，并按约赔付
                </div>
                <div>
                    对您提交的材料进行审核，审核通过后:
                </div>
                <div>
                    A：10000元以下小额损失，1个工作日内支付赔偿
                </div>
                <div>
                    B：10000元以上大额损失，按照保险公司约定时间尽快支付赔偿
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "claim",
        data() {
            return {
                dialogVisible: false
            }
        },
        methods: {
            open() {
                this.dialogVisible = true
            },
            close() {
                this.dialogVisible = false
            }
        }
    }
</script>

<style scoped lang="scss">
    .claim{
        .dialog {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 100%;
            background: white;
            box-sizing: border-box;
            padding: 20px 20%;
            & > div:nth-child(1) {
                width: 100%;
                text-align: center;
            }
            & > div:nth-child(2) {
                display: flex;
                justify-content: center;
                img {
                    width: 200px;
                    height: 200px;
                    margin: 30px 0;
                }
            }

            div {
                font-size: 14px;
                width: 100%;
                text-align: left;
                line-height: 25px;
            }
        }
    }

</style>
