<template>
    <div class="box"
         :style="'background:'+background + (positionShow?';position:fixed;top:0;z-index:999;width:100%;':'') + (box_shadow?'box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.05)':'')">
        <div class="front-header">
            <img v-if="logo==0" src="../../image/login/logo.png" alt=""  @click="tapToggle('index')">
            <img v-else src="../../image/login/logo-1.png" alt="" @click="tapToggle('index')">
            <a href="javascript:;" @click="tapToggle('employerInsurance')"
               :style="(C_chooseTitle==='employerInsurance')?';border-bottom:2px solid ' + fontcolor:''"
               :class="C_chooseTitle==='employerInsurance'?'chooseIt':''"><span
                    :style="'color:' + fontcolor">雇主险产品</span></a>
            <a href="javascript:;" @click="tapToggle('guaranteeSlip')"
               :style="(C_chooseTitle==='guaranteeSlip')?';border-bottom:2px solid ' + fontcolor:''"
               :class="C_chooseTitle==='guaranteeSlip'?'chooseIt':''"><span
                    :style="'color:' + fontcolor">我的保单</span></a>
            <a href="javascript:;" @click="tapToggle('correctOrder')"
               :style="(C_chooseTitle==='correctOrder')?';border-bottom:2px solid ' + fontcolor:''"
               :class="C_chooseTitle==='correctOrder'?'chooseIt':''"><span :style="'color:' + fontcolor">批改列表</span></a>
            <a href="javascript:;" @click="tapToggle('doneNo')"
               :style="(C_chooseTitle==='doneNo')?';border-bottom:2px solid ' + fontcolor:''"
               :class="C_chooseTitle==='doneNo'?'chooseIt':''"><span :style="'color:' + fontcolor">我要理赔</span></a>
            <button :style="'border-color:' + fontcolor+';color:' + fontcolor" @click="logout()">{{login_text}}
            </button>
        </div>
        <claim ref="claim"></claim>
    </div>
</template>

<script>
    import claim from "./dialog/claim";
    export default {
        name: "backHeader",
        components: {
            claim
        },
        data() {
            return {
                login_text: "注销登录",
                box_shadow: false,//阴影
                C_chooseTitle: "",
                dialogVisible: false
            }
        },
        props: {
            chooseTitle: {
                default: 'index',
            },
            background: {
                default: "#1553B1"
            },
            positionShow: {
                default: false
            },
            fontcolor: {
                default: "#fff"
            },
            logo: {
                default: '0'
            },
            C_Title: {}
        },
        created() {
            this.C_chooseTitle = this.C_Title
        },
        methods: {
            tapToggle: function (e) {
                this.C_chooseTitle = e
                // eslint-disable-next-line no-empty
                if (e == 'doneNo') {
                    this.$refs.claim.open()
                }else if(e == 'employerInsurance'){
                    let routeUrl = this.$router.resolve({
                        path: "/employerInsurance",
                    });
                    window.open(routeUrl.href, '_blank');
                } else {
                    this.$router.push({
                        name: e
                    })
                }
            },
            logout: function () {
                this.$confirm('您确定要注销登陆吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$store.state.logininformation = {}
                    window.localStorage.removeItem('logininformation')
                    this.$router.push({name: 'index'})
                    this.$store.state.login_type = 0
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .box {
        background-color: #0053B9;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10;
    }

    .front-header {
        display: flex;
        padding: 10px 0;
        width: 70%;
        margin-left: 15%;
        justify-content: space-between;
        align-items: center;

        & > a {
            color: #333;
            text-decoration: none;
        }

        & > button {
            background-color: transparent;
            width: 70px;
            height: 34px;
            border: 1px solid #333;
            color: #333;
            border-radius: 5px;
        }

        .chooseIt {
            position: relative;
            top: 3px;
            padding-bottom: 4px;
            border-bottom: 2px solid #333;
        }
    }
</style>
