<template>
    <div class="table-box">
        <el-table
                :data="A_tableList"
                border
                style="">
            <el-table-column
                    prop="num_id"
                    label="流水号"
                    min-width="140"
                    align="center">
                    <template slot-scope="scope2">
                    <div>
                        <span>{{scope2.row.num_id==""?"暂无":scope2.row.num_id}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    prop="policy_num"
                    label="保单号"
                    min-width="140"
                    align="center"
                    :render-header="renderHeader">
                <template slot-scope="scope2">
                    <div>
                        <span>{{scope2.row.policy_num==""?"暂无":scope2.row.policy_num}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    prop="applicant_name"
                    label="投保人"
                    min-width="140"
                    align="center">
            </el-table-column>
            <!--            <el-table-column-->
            <!--                    prop="title"-->
            <!--                    label="产品名称"-->
            <!--                    min-width="140"-->
            <!--                    align="center">-->
            <!--            </el-table-column>-->
            <el-table-column
                    prop="company_name"
                    label="保险公司"
                    min-width="100"
                    align="center">
            </el-table-column>
            <el-table-column
                    prop="c_time"
                    label="创建时间"
                    align="center">
            </el-table-column>
            <el-table-column
                    prop="policy_money"
                    label="保费金额"
                    width="120"
                    align="center">
            </el-table-column>
            <el-table-column
                    label="保单状态"
                    align="center"
                    width="120">
                <template slot-scope="scope">
                    <div>
                        <!-- <span class="policyState">{{scope.row.S_policyState}}</span> -->
                        <span>{{status[0][scope.row.type]}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    prop="career_type_name"
                    label="职业类别"
                    width="120"
                    align="center">
            </el-table-column>
            <el-table-column
                    label="操作"
                    min-width="150"
                    align="center">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.type==9" @click="retained(scope.row.retention_code)" type="text" size="small">去编辑</el-button> 
                    <el-button v-if="scope.row.type!=2&&scope.row.type!=9" @click="viewOrder(scope.row.num_id)" type="text" size="small">
                        查看
                    </el-button>
                    <el-button v-if="scope.row.type==2" @click="goPay(scope.row.num_id,scope.row.pay_type,scope.row.pay_type,scope.row.policy_money,scope.row.other)" type="text" size="small">去支付
                    </el-button>
                    <el-button v-if="scope.row.type==2" @click="deleteOrder(scope.row.num_id)" type="text" size="small">
                        删除
                    </el-button>
                    <!--                    <el-button v-if="scope.row.type==1||scope.row.type==5" @click="applyRefund(scope.row.num_id)" type="text" size="small">申请退费</el-button>-->
                    <el-button v-if="scope.row.type==6||scope.row.type==1" @click="correct(scope.row.num_id)"
                               type="text" size="small">申请批改
                    </el-button>
                    <el-button v-if="scope.row.type==4||scope.row.type==8" @click="buy(scope.row.num_id)" type="text"
                               size="small">重新购买
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <pay ref="payVisible"  :paytype="paytype" :payDis="payDis" :num_id='num_id' :company_bank_name='company_bank_name' :company_bank_no="company_bank_no" :company_name="company_name" :totalMoney="totalMoney"></pay>
    </div>
</template>

<script>
import pay from "../../components/back/pay";
    export default {
        name: "backTable",
         components: {
            pay
        },
        props: ["type"],
        data() {
            return {
                status: [{
                    1: "保障中",
                    2: "待支付",
                    4: "已失效",
                    5: "待生效",
                    8: "已取消",
                    9: "未完成"
                }],
                A_tableList: [],//数据
                paytype:'',//去支付使用 支付方式
                payDis:"",  //禁用哪种支付方式
                num_id:'',//去支付使用
                company_bank_name:"",//去支付使用开户行
                company_bank_no:'',//去支付使用账户
                company_name:"",//去支付使用账户名
                totalMoney:'',//去支付使用总保费

            }
        },
        mounted() {
            console.log(this.type)
            this.getOrders(this.type)
        },
        methods: {
            // 加载订单数据
            getOrders: function (type) {
                console.log(type)
                let that = this;
                let loading = that.$loading({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                that.axios({
                    url: this.$store.state.baseUrl + '/api/product/Order/getOrders',
                    method: 'POST',
                    params: {
                        type: type,
                        uid: that.$store.state.logininformation.uid
                    }
                }).then(function (res) {
                    console.log(res)
                    loading.close();
                    if (res.data.code == 200) {
                        that.A_tableList = res.data.data
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
             // 去支付
            goPay: function (num_id,pay_type,payDis,policy_money,other) {
                if(pay_type!=1){//去支付使用 支付方式
                    this.paytype='0'
                }else{
                    this.paytype='1'
                }
                this.payDis=payDis+'',  //禁用哪种支付方式 0
                this.num_id=num_id,//去支付使用
                this.company_bank_name=other.company_bank_name,//去支付使用开户行
                this.company_bank_no=other.company_bank_no,//去支付使用账户
                this.company_name=other.company_name,//去支付使用账户名
                this.totalMoney=policy_money,//去支付使用总保费
                this.$refs.payVisible.open()//
            },
            //删除支付
            deleteOrder: function (e) {
                console.log(e)
                let that = this;
                that.$confirm('您是否要删除此订单?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    let loading = that.$loading({
                        lock: true,
                        text: 'Loading',
                        background: 'rgba(0, 0, 0, 0.5)'
                    });
                    that.axios({
                        url: that.$store.state.baseUrl + '/api/product/order/noPay',
                        method: 'POST',
                        params: {
                            num_id: e
                        }
                    }).then(function (res) {
                        console.log(res)
                        loading.close();
                        that.getOrders(that.type)
                    }).catch(function (error) {
                        console.log(error);
                    });
                }).catch(() => {
                    that.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            // 查看订单
            viewOrder: function (e) {
                this.$router.push({
                    name: 'guaranteeLook',
                    query: {
                        num_id: e
                    }
                })
            },
            // 申请退费
            applyRefund: function (e) {
                console.log(e)
                this.$alert('客服电话：400-616-2199 ', '请电话联系客服', {
                    confirmButtonText: '确定',
                });
            },
            // 申请批改
            correct: function (e) {
                this.$router.push({
                    name: 'applyCorrect',
                    query: {
                        num_id: e
                    }
                })
            },
            // 重新购买
            buy: function (e) {
                console.log(e)
                this.$router.push({name: 'guaranteeSlip'})
            },
            // 转跳投保数据留存
            retained:function(e){
                console.log(e)
                this.$router.push({
                    name: 'insure',
                    query: {
                       retention_code:e,
                       continue:1
                    }
                })
            },
            renderHeader:function(h,{column}){
                console.log(h)
                console.log(column)
                return h(
                    'div',[ 
                        h('span', column.label),
                        h('el-tooltip',{
                            props:{
                                effect:'light',
                                content:'保司下发保单号，会有几分钟的延迟',
                                placement:'right-start'
                                },
                            },
                            [
                            h('i', {
                                class:'el-icon-question',
                                style:'color:#409eff;margin-left:5px;'
                                })
                            ],
                        )
                    ]
    　　　　　　);
            }
            // handleSizeChange(e) { //分页使用暂时取消
            //     console.log(e)
            // },
            // handleCurrentChange(e) { //分页使用暂时取消
            //     console.log(e)
            // }
        }
    }
</script>

<style scoped lang="scss">
    .policyState {
        color: dodgerblue;
    }

    .page {
        width: 50%
    }
</style>
