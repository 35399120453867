import { render, staticRenderFns } from "./claim.vue?vue&type=template&id=1e7d63e6&scoped=true"
import script from "./claim.vue?vue&type=script&lang=js"
export * from "./claim.vue?vue&type=script&lang=js"
import style0 from "./claim.vue?vue&type=style&index=0&id=1e7d63e6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e7d63e6",
  null
  
)

export default component.exports